<!--
 * @Author: your name
 * @Date: 2021-11-09 10:30:44
 * @LastEditTime: 2022-02-10 11:32:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\visualization\video-monitor\index.vue
-->
<template>
  <div>
    <div class="wall">
      <div class="leftTree">
        <org-tree ref="treeRef" @node-click="handleNodeClick" @on-sort="onSort"></org-tree>
      </div>
      <!-- 右边 -->
      <div class="rightTree">
        <div class="rightTreeItem">
          <div style="position: relative; height: 100%; width: 100%;">
            <!-- 16分屏 -->
            <div class="rightTreeTopOne" v-if="isFour === 4 && isRender">
              <div
                v-for="(item, index) in cameras"
                :key="index"
                class="rightTreeTopItem"
                @click="offFour(index, item)"
                :class="activeClass1 == index ? 'active' : ''"
              >
                <div v-if="item.cameraStatus === 1 || item.cameraStatus === 0">
                  <div v-if="!item.cameraStatus" @click="operation(item)">
                    <video :id="'webrtcPlayer' + index" style="width: 100%;height: 150px;" controls autoplay />
                  </div>
                  <div v-if="item.cameraStatus" class="rightTreeTopItemOne">
                    <img src="~@/assets/images/5385@2x.png" />
                    <p>摄像机已离线</p>
                  </div>
                  <div class="rightTreeTopName">
                    <span style="margin-right: 8px;">
                      {{ item.deviceName }}
                    </span>
                    <span>{{ item.sysUqNo }}</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="rightTreeTopItemTwo"
                    :class="activeClass1 == index ? 'active' : ''"
                    @click="offFour(index, item)"
                  >
                    <div class="rightTreeTopItemTwoImg">
                      <img src="~@/assets/images/5380@2x.png" alt />
                      <p>暂无图像</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 4分屏 -->
            <div class="rightTreeTopTwo" v-if="isFour === 2 && isRender">
              <div
                v-for="(item, index) in camerasTwo"
                :key="index"
                :class="activeClass1 == index ? 'active' : ''"
                class="rightTreeTopItem"
                @click="offFour(index, item)"
              >
                <div v-if="item.cameraStatus === 1 || item.cameraStatus === 0">
                  <div v-if="!item.cameraStatus" @click="operation(item)">
                    <div>
                      <video :id="'webrtcPlayer' + index" style="width: 100%;height: 285px;" controls autoplay />
                    </div>
                  </div>
                  <div class="rightTreeTopItemTwoImg2" v-if="item.cameraStatus">
                    <img src="~@/assets/images/5385@2x.png" alt />
                    <p>摄像机已离线</p>
                  </div>
                  <!-- </div> -->
                  <div class="rightTreeTopName">
                    <span style="margin-right: 8px;">
                      {{ item.deviceName }}
                    </span>
                    <span>{{ item.sysUqNo }}</span>
                  </div>
                </div>
                <div v-else>
                  <div
                    @click="offFour(index, item)"
                    class="rightTreeTopItemTwo1"
                    :class="activeClass1 == index ? 'active' : ''"
                  >
                    <div class="rightTreeTopItemTwoImg">
                      <img src="~@/assets/images/5380@2x.png" alt />
                      <p>暂无图像</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 9分屏 -->
            <div class="rightTreeTopThree" v-if="isFour === 3 && isRender">
              <div
                v-for="(item, index) in camerasThree"
                :key="index"
                class="rightTreeTopItem"
                @click="offFour(index, item)"
                :class="activeClass1 == index ? 'active' : ''"
              >
                <div v-if="item.cameraStatus === 1 || item.cameraStatus === 0">
                  <div v-if="!item.cameraStatus" @click="operation(item)">
                    <div class="rightTreeTopWeb">
                      <video :id="'webrtcPlayer' + index" style="width: 100%;height: 220px;" controls autoplay />
                    </div>
                  </div>
                  <div
                    v-if="item.cameraStatus"
                    class="rightTreeTopItemTwo"
                    :class="activeClass1 == index ? 'active' : ''"
                  >
                    <img src="~@/assets/images/5385@2x.png" alt />
                    <p>摄像机已离线</p>
                  </div>
                  <div class="rightTreeTopName">
                    <span style="margin-right: 8px;">
                      {{ item.deviceName }}
                    </span>
                    <span>{{ item.sysUqNo }}</span>
                  </div>
                </div>
                <div v-else @click="offFour(index, item)">
                  <div class="rightTreeTopItemTwo1" :class="activeClass1 == index ? 'active' : ''">
                    <img src="~@/assets/images/5380@2x.png" alt />
                    <p>暂无图像</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 单屏 -->
            <div class="rightTreeTopOnefd" v-if="isFour === 1 && isRender">
              <div class="rightTreeTopItem active">
                <div v-if="treeChildren.cameraStatus === 1 || treeChildren.cameraStatus === 0">
                  <div class="videoSize" v-if="!treeChildren.cameraStatus">
                    <video id="webrtcPlayer" style="width: 100%;height: 670px;" controls autoplay />
                    <div class="rightTreeTopName">
                      <span>{{ treeChildren.deviceName }}</span>
                      <span style="margin-left: 10px;">
                        {{ treeChildren.sysUqNo }}
                      </span>
                    </div>
                  </div>
                  <div v-show="treeChildren.cameraStatus" class="rightTreeTopItemTwofd">
                    <div class="topitemFd">
                      <img src="~@/assets/images/5385@2x.png" />
                      <p class="topitemFdP">摄像机已离线</p>
                    </div>
                  </div>
                </div>
                <div class="rightTreeTopItemTwo active" v-else>
                  <div class="rightTreeTopItemTwoImg">
                    <img src="~@/assets/images/5380@2x.png" />
                    <p>暂无图像</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightTreeButtom">
            <div></div>
            <div>
              <!-- <i class=""></i> -->
              <el-button style="margin: 0 16px 0 0" @click="openOne">单屏</el-button>
              <el-button style="margin: 0 16px 0 0" @click="openTwo">2 X 2</el-button>
              <el-button style="margin: 0 16px 0 0" @click="openThree">3 X 3</el-button>
              <el-button style="margin: 0 16px 0 0" @click="openSwitch">4 X 4</el-button>
              <img
                v-if="isFour == 1"
                @click="fullScreenOne"
                style="height: 24px; width: 24px; margin: 0 16px 0 0"
                src="~@/assets/images/button5-normal@2x.png"
              />
              <img
                v-if="isFour == 2"
                @click="fullScreenTwo"
                style="height: 24px; width: 24px; margin: 0 16px 0 0"
                src="~@/assets/images/button5-normal@2x.png"
              />
              <img
                v-if="isFour == 3"
                @click="fullScreenThree"
                style="height: 24px; width: 24px; margin: 0 16px 0 0;"
                src="~@/assets/images/button5-normal@2x.png"
              />
              <img
                v-if="isFour == 4"
                @click="fullScreen"
                style="height: 24px; width: 24px; margin: 0 16px 0 0;"
                src="~@/assets/images/button5-normal@2x.png"
              />
            </div>
          </div>
        </div>
        <!-- 操控台 -->
        <div class="videoControl">
          <div v-if="isSelect == 0">
            <div class="controlTitle">
              <p>云台控制</p>
              <span style="margin-right: 30px;">暂无</span>
              <span>暂无</span>
            </div>
            <div class="videoCore">
              <img style="text-align: center;" src="~@/assets/images/5611no(1).png" />
              <div>
                <img src="~@/assets/images/5611no.png" />
                <img style="margin: 30px 30px 30px 30px;" src="~@/assets/images/367.png" />
                <img src="~@/assets/images/5612no.png" />
              </div>
              <img src="~@/assets/images/5612no(1).png" />
            </div>
            <div class="videoOpen">
              <img style="margin-right: 20px;" src="~@/assets/images/no5457.png" />
              <img src="~@/assets/images/no5460.png" />
            </div>
          </div>
          <div v-if="isSelect == 1">
            <div class="controlTitle">
              <p>云台控制</p>
              <span style="margin-right: 30px;">{{ operationData.deviceName || '暂无' }}</span>
              <span>{{ operationData.sysUqNo || '暂无' }}</span>
            </div>
            <div class="videoCore">
              <img
                style="text-align: center;"
                :src="img1"
                @mouseenter="enter"
                @mouseleave="leave"
                @mousedown="handleControlCameraDirection('up')"
                @mouseup="handleControlCameraDirection('stop')"
              />
              <div>
                <img
                  @mouseenter="enter2"
                  @mouseleave="leave2"
                  @mousedown="handleControlCameraDirection('left')"
                  @mouseup="handleControlCameraDirection('stop')"
                  :src="img2"
                />
                <img style="margin: 30px 30px 30px 30px;" src="~@/assets/images/367.png" />
                <img
                  @mouseenter="enter3"
                  @mouseleave="leave3"
                  @mousedown="handleControlCameraDirection('right')"
                  @mouseup="handleControlCameraDirection('stop')"
                  :src="img3"
                />
              </div>
              <img
                @mouseenter="enter4"
                @mouseleave="leave4"
                @mousedown="handleControlCameraDirection('down')"
                @mouseup="handleControlCameraDirection('stop')"
                :src="img4"
              />
            </div>
            <div class="videoOpen">
              <img
                style="margin-right: 20px;"
                @mouseenter="enter5"
                @mouseleave="leave5"
                @mousedown="handleControlCameraZoom('zoomin')"
                @mouseup="handleControlCameraZoom('stop')"
                :src="imgEnlarge"
              />
              <img
                :src="imgNarrow"
                @mouseenter="enter6"
                @mouseleave="leave6"
                @mousedown="handleControlCameraZoom('zoomout')"
                @mouseup="handleControlCameraZoom('stop')"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCamera, cameraDirectionControl } from '@/api/general-user/video-monitor.js';
import { toPlay, toStop } from '@/api/general-user/sceen';
import OrgTree from '@/components/VideoTree';

export default {
  components: {
    OrgTree
  },
  data() {
    return {
      cameras: [],
      isFour: 2, // 默认进来是几成几的页面 默认是 2 X 2
      camerasTwo: [],
      camerasThree: [],
      camerasOne: [],
      treeChildren: {},
      operationData: {
        deviceName: '',
        sysUqNo: ''
      },
      img1: require('@/assets/images/5611(1).png'),
      img2: require('@/assets/images/5611.png'),
      img3: require('@/assets/images/5612.png'),
      img4: require('@/assets/images/5612(1).png'),
      imgEnlarge: require('@/assets/images/5457.png'),
      imgNarrow: require('@/assets/images/5460.png'),
      isSelect: 0,
      activeClass1: -1, // 0为默认选择第一个，-1为不选择
      windowId: '',
      // ======= 云台控制 ======
      currentDeviceId: '',
      videoData: [],
      videoId: '', // 进来显示默认机构摄像头 传空
      isRender: true,
      playToStop: {
        app: 'live',
        stream: ''
      }
    };
  },

  mounted() {
    this.getCamera();
    window.addEventListener('beforeunload', e => this.pageclose());
  },
  methods: {
    pageclose() {
      if (this.videoData.length) {
        this.videoData.forEach(item => {
          this.toStop(item.urlParams.stream);
          item.destroy();
        });
      }
    },
    // 获取机位 默认进来是 4 个
    getCamera(numberData = 4) {
      getCamera({ orgId: this.videoId }).then(res => {
        if (this.videoData.length) {
          this.videoData.forEach(item => {
            this.toStop(item.urlParams.stream)
            item.destroy();
          });
            this.videoData=[];
        }

        let defaultData = [];
        for (let i = 0; i < numberData; i++) {
          // 循环传过来的次数 先默认在里面设为没有摄像机
          defaultData.push({
            cameraStatus: 2
          });
        }
        // 如果传过来的数量比当前机构的多,就使用后端返的值,否则用只渲染有的数量,超出的就不管
        let dataLength = defaultData.length > res.data.length ? res.data.length : defaultData.length;
        // 赋值有几个赋值几个,剩余的还是上面给的默认值
        for (let i = 0; i < dataLength; i++) {
          defaultData[i] = { ...res.data[i] };
        }
        if (this.isFour === 2) {
          this.camerasTwo = defaultData;
        } else if (this.isFour === 4) {
          this.cameras = defaultData;
        } else if (this.isFour === 3) {
          this.camerasThree = defaultData;
        }
        this.treeChildren = defaultData[0];
        let count = 0;
        let winId = '';
        // 进页面就赋值样式
        for (let i = 0; i <= defaultData.length - 1; i++) {
          // console.log(this.cameras[i]);
          // 进来先把第一个机位赋值
          winId = defaultData[0].id;
          this.currentDeviceId = defaultData[0].deviceId;
          this.operationData = defaultData[0];
          count = 0;
          // 如果有空机位,优先选择空机位
          if (defaultData[i]?.cameraStatus === 0) {
            winId = defaultData[i].id;
            count = i;
            break;
          }
        }
        this.windowId = winId;
        // // 赋值红框样式
        this.activeClass1 = count;
        var _this = this;
        // 拿到第一个赋值的id
        var isNormal = res.data.filter(item => {
          return item.id == _this.windowId;
        });
        // 判断第一个是正常的吗,是就打开云台操控
        if (isNormal[0].cameraStatus === 0) {
          this.isSelect = 1;
        } else {
          this.isSelect = 0;
        }
        this.videoData = [];
        this.$nextTick(() => {
          res.data.forEach((res, i) => {
            let video = document.getElementById('webrtcPlayer' + i);
            // console.log(video);
            let url = res.webrtcUrl;
            if (video) {
              let player = new JSWebrtc.Player(url, {
                video: video,
                autoplay: true
              });
              this.videoData.push(player);
              this.toPlay(url);
            }
          });
        });
      });
    },
    toPlay(url) {
      var streamarr = url.split('/');
      var stream = streamarr[streamarr.length - 1];
      this.playToStop.stream = stream;
      toPlay(this.playToStop).then(res => {});
    },
    toStop(url) {
      this.playToStop.stream = url;
      toStop(this.playToStop).then(res => {});
    },
    toStop1(url){
      var streamarr = url.split('/');
      var stream = streamarr[streamarr.length - 1];
      this.playToStop.stream = stream;
      toStop(this.playToStop).then(res => {});
    },
    enter() {
      this.img1 = require('@/assets/images/56111(1).png');
    },
    enter2() {
      this.img2 = require('@/assets/images/56111.png');
    },
    enter3() {
      this.img3 = require('@/assets/images/56121.png');
    },
    enter4() {
      this.img4 = require('@/assets/images/56121(1).png');
    },
    leave() {
      this.img1 = require('@/assets/images/5611(1).png');
    },
    leave2() {
      this.img2 = require('@/assets/images/5611.png');
    },
    leave3() {
      this.img3 = require('@/assets/images/5612.png');
    },
    leave4() {
      this.img4 = require('@/assets/images/5612(1).png');
    },
    enter5() {
      this.imgEnlarge = require('@/assets/images/54571.png');
    },
    leave5() {
      this.imgEnlarge = require('@/assets/images/5457.png');
    },
    enter6() {
      this.imgNarrow = require('@/assets/images/54601.png');
    },
    leave6() {
      this.imgNarrow = require('@/assets/images/5460.png');
    },
    // 创建单屏webrtc
    webrtcPlayerApi() {
      if (this.videoData.length) {
          this.videoData.forEach(item => {
            this.toStop(item.urlParams.stream)
            item.destroy();
          });
            this.videoData=[];
        }
      if(this.treeChildren){
        this.toStop1(this.treeChildren.webrtcUrl);
      }
      this.$nextTick(() => {
        let video = document.getElementById('webrtcPlayer');
        // console.log(video);
        let url = this.treeChildren.webrtcUrl;
        if (video) {
          let player = new JSWebrtc.Player(url, {
            video: video,
            autoplay: true
          });
          this.toPlay(url);
        }
      });
    },
    openOne() {
      this.isFour = 1;
      this.webrtcPlayerApi();
    },
    openTwo() {
      // this.videoId = '';
      this.isFour = 2;
      this.getCamera(4);
    },
    openThree() {
      // this.videoId = '';
      this.isFour = 3;
      this.getCamera(9);
    },
    openSwitch() {
      // this.videoId = '';
      this.isFour = 4;
      this.getCamera(16);
    },
    // 节点被点击时回调
    handleNodeClick(data) {
      this.isRender = false;
      setTimeout(() => {
        this.isRender = true;
        this.videoId = data.id;
        if (!data.isDevice) {
          this.isFour = 1;
          // 是否故障还未写
          this.getOneVideoData();
        } else {
          this.isFour = 2;
          this.getCamera();
        }
      }, 200);
    },
    // 获取单屏数据
    getOneVideoData() {
      getCamera({ orgId: this.videoId }).then(res => {
        if (this.videoData.length) {
          this.videoData.forEach(item => {
            this.toStop(item.urlParams.stream);
            item.destroy();
          });
            this.videoData=[];
        }
        if(this.treeChildren){
          this.toStop1(this.treeChildren.webrtcUrl);
        }
        this.treeChildren = res.data[0];
        this.operationData = res.data[0];
        // console.log(this.treeChildren);
        this.$nextTick(() => {
          let video = document.getElementById('webrtcPlayer');
          // console.log(video);
          let url = res.data[0].webrtcUrl;
          if (video) {
            let player = new JSWebrtc.Player(url, {
              video: video,
              autoplay: true
            });
            this.toPlay(url);
          }
        });
      });
    },
    fullScreen() {
      this.openEnlarge('.rightTreeTopOne');
    },
    fullScreenThree() {
      this.openEnlarge('.rightTreeTopThree');
    },
    fullScreenTwo() {
      this.openEnlarge('.rightTreeTopTwo');
    },
    fullScreenOne() {
      this.openEnlarge('.rightTreeTopOnefd');
    },
    // 全屏方法
    openEnlarge(name) {
      let element = document.querySelector(name); //设置后就是   id==con_lf_top_div 的容器全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    // 点击任何一个窗口判断是否有没有流
    offFour(index, item) {
      this.treeChildren = item;
      if (item.cameraStatus == 0) {
        this.isSelect = 1;
      } else {
        this.isSelect = 0;
      }
      this.activeClass1 = index;
      this.windowId = item.id;
      this.currentDeviceId = item.deviceId;
      this.treeChildren = item;
    },
    onSort() {
      if (this.isFour == 2) {
        this.getCamera(4);
      } else if (this.isFour == 3) {
        this.getCamera(9);
      } else if (this.isFour == 4) {
        this.getCamera(16);
      } else {
        this.getCamera();
      }
      this.$refs.treeRef.getOrgTreeData();
    },
    // 点击任意一个窗口触发
    operation(item) {
      this.isSelect = 1;
      if (item !== undefined) {
        this.operationData = item.deviceDetailResult;
      }
    },
    // 云台控制
    // 按下方向键  operate:  方向控制 left-向左 right-向右 up-向上 down-向下 stop(停止)
    handleControlCameraDirection(operate) {
      cameraDirectionControl({
        deviceId: this.currentDeviceId,
        operate
      })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        });
    },
    handleControlCameraZoom(operate) {
      cameraZoomControl({
        deviceId: this.currentDeviceId,
        operate
      })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        });
    }
  },
  beforeDestroy() {
    if (this.videoData.length) {
      this.videoData.forEach(item => {
        this.toStop(item.urlParams.stream);
        item.destroy();
      });
    }
  },
  watch: {
  }
};
</script>
<style lang="less" scoped>
.wall {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  .leftTree {
    width: 310px;
    min-width: 240px;
    height: 805px;
    // background-color: #f4f4f4;
    background: #fff;
    box-sizing: border-box;
    overflow: auto;
  }

  .rightTree {
    height: 805px;
    width: 100%;
    // border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    .rightTreeItem {
      position: relative;
      width: 80%;
      height: 80%;
      .rightTreeTopOne {
        height: 100%;
        background-color: #06111f;
        display: flex;
        padding: 16px 0 0 16px;
        min-width: 900px;
        flex-wrap: wrap;
        overflow: hidden;
        border-radius: 5px;
        .rightTreeTopItem {
          width: 23%;
          flex-grow: 1;
          height: 20%;
          margin: 0 16px 16px 0;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          // opacity: 1;
          background-color: #000000;
          .rightTreeTopName {
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            background-color: #000000;
            // opacity: 0.8;
            padding: 2px 6px;
            color: #c4bfbf;
            .triangle {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              width: 0 !important;
              height: 0 !important;
              z-index: 100;
              text-align: right;
              width: 50px !important;
              // height: 50px !important;
              color: #1fb3f7;
              text-decoration: underline;
              font-size: 14px;
            }
          }
          .rightTreeTopItemOne {
            width: 100%;
            position: absolute;
            height: 100%;
            text-align: center;
            background-color: #000102;
            img {
              margin-top: 10%;
              width: 70px;
              height: 70px;
            }
            p {
              margin: 10px 0 0 0;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
          }
          .rightTreeTopItemTwo {
            background-color: #c0c4ce;
            width: 100%;
            flex-grow: 1;
            position: absolute;
            height: 100%;
            // margin: 0 16px 16px 0;
            text-align: center;

            .rightTreeTopItemTwoImg {
              width: 100%;
              height: 130px;
              // text-align: center;
              p {
                margin: 5px 14px 0 0;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #90939b;
              }
              img {
                width: 70px;
                height: 70px;
                margin-top: 10%;
              }
            }
          }
        }
        .active {
          border: 1px solid red;
        }
      }
      .rightTreeTopTwo {
        height: 100%;
        display: flex;
        padding: 16px 0 0 16px;
        flex-wrap: wrap;
        background-color: #06111f;
        .rightTreeTopItem {
          width: 45%;
          flex-grow: 1;
          height: 40%;
          margin: 0 16px 16px 0;
          position: relative;
          overflow: hidden;
          background-color: #000102;
          cursor: pointer;
          .rightTreeTopName {
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            background-color: #000000;
            opacity: 0.8;
            padding: 2px 6px;
            color: #c4bfbf;
            .triangle {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              width: 0 !important;
              height: 0 !important;
              // background: url("~@/assets/images/5413@2x.png");
              // background-size: 100% 100%;
              z-index: 100;
              text-align: right;
              width: 50px !important;
              height: 50px !important;
              color: #1fb3f7;
              text-decoration: underline;
              font-size: 14px;
            }
          }
          .rightTreeTopItemTwoImg2 {
            width: 100%;
            height: 110%;
            position: absolute;
            background-color: #000102;
            text-align: center;
            img {
              margin-top: 15%;
            }
            p {
              margin: 10px 0 0 0;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
          }
          // .rightTreeTopItemTwo {
          //   background-color: #000102;
          //   width: 100%;
          //   flex-grow: 1;
          //   height: 100%;
          //   // margin: 0 16px 16px 0;
          // }
          .rightTreeTopItemTwo1 {
            background-color: #c0c4ce;
            width: 100%;
            flex-grow: 1;
            position: absolute;
            text-align: center;
            height: 110%;
            // margin: 0 16px 16px 0;
            .rightTreeTopItemTwoImg {
              width: 100%;
              // height: 250px;
              text-align: center;
              // margin: 40px 0 10px 0;
              p {
                margin: 10px 14px 0 0;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #90939b;
              }
            }
            img {
              margin-top: 15%;
            }
            p {
              margin: 10px 0 0 0;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
          }
        }
        .active {
          border: 1px solid red;
        }
      }
      .rightTreeTopThree {
        height: 100%;
        display: flex;
        padding: 16px 0 0 16px;
        flex-wrap: wrap;
        background-color: #06111f;
        .rightTreeTopItem {
          width: 30%;
          flex-grow: 1;
          height: 30%;
          margin: 0 16px 0 0;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          .rightTreeTopName {
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            background-color: #000000;
            opacity: 0.8;
            padding: 2px 6px;
            color: #c4bfbf;
            .triangle {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              width: 0 !important;
              height: 0 !important;
              // background: url("~@/assets/images/5413@2x.png");
              // background-size: 100% 100%;
              z-index: 100;
              text-align: right;
              width: 50px !important;
              height: 50px !important;
              color: #1fb3f7;
              text-decoration: underline;
              font-size: 14px;
            }
          }
          .rightTreeTopWeb {
            width: 100%;
            height: 100%;
          }
          .rightTreeTopItemTwo {
            background-color: #000102;
            width: 100%;
            text-align: center;
            position: absolute;
            overflow: hidden;
            // top: 30px;
            flex-grow: 1;
            height: 100%;
            // margin: 0 16px 16px 0;
            p {
              margin: 10px 0 0 0;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
            img {
              width: 70px;
              height: 70px;
              margin-top: 10%;
            }
          }
          .rightTreeTopItemTwo1 {
            background-color: #c0c4ce;
            width: 100%;
            flex-grow: 1;
            height: 100%;
            position: absolute;
            text-align: center;
            margin: 0 16px 0 0;
            p {
              margin: 10px 14px 0 0;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
            img {
              margin-top: 10%;
            }
          }
        }
        .active {
          border: 1px solid red;
        }
      }
      .rightTreeTopOnefd {
        height: 100%;
        // width: 100%;
        display: flex;
        // padding: 16px 0 0 16px;
        flex-wrap: wrap;
        overflow: hidden;
        border-radius: 5px;
        .rightTreeTopItem {
          width: 23%;
          flex-grow: 1;
          height: 100%;
          margin: 0 16px 16px 0;
          // position: relative;
          // cursor: pointer;
          // opacity: 1;
          .triangle {
            position: absolute !important;
            top: 0 !important;
            right: 0 !important;
            width: 0 !important;
            height: 0 !important;
            z-index: 100;
            color: #1fb3f7;
            width: 50px !important;
            height: 50px !important;
            font-size: 14px;
            text-decoration: underline;
          }
          .rightTreeTopName {
            width: 97%;
            position: absolute;
            top: 0;
            z-index: 10;
            display: flex;
            background-color: #000000;
            opacity: 0.8;
            padding: 2px 6px;
            color: #c4bfbf;
          }
        }
        .rightTreeTopItemTwo {
          background-color: #c0c4ce;
          position: absolute;
          width: 100%;
          flex-grow: 1;
          height: 100%;
          .rightTreeTopItemTwoImg {
            // width: 100%;
            // height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -40px;
            margin-left: -44px;
            p {
              margin: 10px 0 0 10px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #90939b;
            }
          }
        }
        .rightTreeTopItemTwofd {
          background-color: #000102;
          width: 100%;
          height: 100%;

          position: absolute;
          .topitemFd {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -48px;
            margin-left: -48px;
            .topitemFdP {
              margin-top: 20px;
              margin-left: 12px;
              color: #ffffff;
            }
          }
        }
      }
      .rightTreeButtom {
        margin-top: 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-radius: 5px;
        // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      }
    }
    .videoControl {
      height: 90%;
      width: 20%;
      background: #f3f6fe;
      margin-top: 6%;
      position: relative;
      .controlTitle {
        text-align: center;

        p {
          margin: 20px 0 10px 0;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          opacity: 1;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          opacity: 1;
        }
      }
      .videoCore {
        position: absolute;
        top: 30%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        img {
          cursor: pointer;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .videoOpen {
        position: absolute;
        width: 124px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
